import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const RainbowMenu = ({ active, relativePath }) => {
  const entries = [
    { title: "Willkommen", slug: "/" },
    { title: "Galerie", slug: "posts" },
    { title: "Newsletter", slug: "newsletter" },
    { title: "Team", slug: "team" },
    { title: "Links", slug: "links" },
    { title: "Impressum", slug: "imprint" },
  ]
  relativePath = relativePath ? relativePath : '';

  const [isNavBurgerActive, setNavBurgerActive] = useState(false);

  return (
    <>
      <nav class="navbar mb-3" role="navigation" arial-label="main navigation">
        <div class="navbar-brand">
          <a onClick={() => setNavBurgerActive(!isNavBurgerActive)} role="button" class={`navbar-burger ${isNavBurgerActive ? "is-active": ""}`} aria-label="menu" aria-expanded="false">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${isNavBurgerActive ? "is-active" : ""} columns rainbow mx-0 buttons are-medium pt-1`}>
          {entries.map((entry) => (
            <div key={entry.title} className="navbar-item column rainbow-entry has-text-centered is-2 px-0">
              <Link className="button is-fullwidth has-text-weight-semibold mb-1" to={relativePath + entry.slug}>{entry.title}</Link>
              {entry.title === active ? <>
                <div className="is-fullwidth pt-2 is-selected"></div>
              </>
                : <>
                  <div className="is-fullwidth has-background-grey-lighter pt-2"></div>
                </>
              }
            </div>
          ))}
        </div>
      </nav>
    </>
  )
}