import React, { useState } from "react";

export const Sidebar = ({ title, thumbnail, full }) => {
  return (
    <>
            <h2 className="title is-4">Kontakt</h2>
            <div className="mb-4">
              Melde dich bei unserem <a href="/newsletter">Newsletter</a> an und verpasse keine Events.
            </div>
            <div className="mb-4">
             Du hast Fragen, Anregungen, Wünsche? Dann schreib uns einfach an <a href="mailto:smag@fliederlich.de">smag@fliederlich.de</a>.
            </div>
    </>
  )
}